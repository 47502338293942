import { useMutation, useQuery } from "@apollo/client";
import {
    Course,
    CourseMembership,
    PeerGroup,
    PeerGroupCycle,
    PeerGroupStatus,
    PeerGroupTimeSlot,
    HourMinute24h,
    PeerGroupTimeSlotInUserTimeZone,
} from "@app/shared/types";
import {
    Alert,
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    GRAPHQL_MUTATION_ADD_PEER_GROUP_TIMESLOT,
    GRAPHQL_MUTATION_DELETE_PEER_GROUP_TIMESLOT,
    GRAPHQL_QUERY_ALL_COURSE_MEMBERSHIPS_FOR_COURSE,
    GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
    GRAPHQL_QUERY_COURSE,
    GRAPHQL_MUTATION_MOVE_USER_TO_PEER_GROUP_AS_ADMIN,
    GRAPHQL_MUTATION_OPEN_NEW_PEER_GROUP,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";
import { selectIsAdmin } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { DateTime, WeekdayNumbers } from "luxon";
import { useEffect, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import {
    convertAndFormatPeerGroupTimeSlotToUserTimeZone,
    convertPeerGroupTimeSlotUserTimeZoneToUTC,
    convertPeerGroupTimeSlotUTCToUserTimeZone,
    formatPeerGroupTimeSlot,
} from "./courseHelpers";
import { CSDialog } from "components/CSDialog";
import _ from "lodash";
import { CSSelectField } from "components/CSSelectField";
import { getDayFromWeekdayNumber, getPeerGroupTimeSlotId, IANATimezones } from "@app/shared/utils";
import { useSnackbar } from "components/SnackbarContext";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
};

export const CoursePeerGroupAdminPage = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { showSnackbar } = useSnackbar();

    const [tabValue, setTabValue] = useState<number>(0);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [timeSlotToDelete, setTimeSlotToDelete] = useState<PeerGroupTimeSlot | null>(null);

    const [newTimeSlotWeekday, setNewTimeSlotWeekday] = useState<WeekdayNumbers>(1);
    const [newTimeSlotStartTime, setNewTimeSlotStartTime] = useState<HourMinute24h>("00:00");
    const [newTimeSlotCycle, setNewTimeSlotCycle] = useState<PeerGroupCycle>("biweekly");

    const [newTimeSlotDialogOpen, setNewTimeSlotDialogOpen] = useState<boolean>(false);

    const [moveToPeerGroupDialogOpen, setMoveToPeerGroupDialogOpen] = useState<boolean>(false);
    const [chosenPeerGroupToMoveUserTo, setChosenPeerGroupToMoveUserTo] = useState<string | null>(
        null,
    );
    const [userIdToMoveToNewPeerGroup, setUserIdToMoveToNewPeerGroup] = useState<string | null>(
        null,
    );

    const [openNewPeerGroupDialogOpen, setOpenNewPeerGroupDialogOpen] = useState<boolean>(false);
    const [newPeerGroupTimeSlotId, setNewPeerGroupTimeSlotId] = useState<string | null>(null);
    const [openNewPeerGroupMutation] = useMutation(GRAPHQL_MUTATION_OPEN_NEW_PEER_GROUP, {
        refetchQueries: [
            {
                query: GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
                variables: {
                    courseId: courseId,
                },
            },
        ],
    });

    const defaultTimezone = "America/Los_Angeles";
    const [timezone, setTimezone] = useState(defaultTimezone);
    const [timezoneOffset, setTimezoneOffset] = useState(
        DateTime.local().setZone(defaultTimezone).offsetNameShort,
    );

    useEffect(() => {
        const newOffset = DateTime.local().setZone(timezone).offsetNameShort;
        setTimezoneOffset(newOffset);
    }, [timezone]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const isAdmin = useSelector(selectIsAdmin);

    const { data: peerGroupData, loading: peerGroupsLoading } = useQuery(
        GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
        {
            variables: {
                courseId: courseId,
            },
        },
    );

    const { data: courseMembershipData, loading: courseMembershipLoading } = useQuery(
        GRAPHQL_QUERY_ALL_COURSE_MEMBERSHIPS_FOR_COURSE,
        {
            variables: {
                courseId: courseId,
            },
        },
    );

    const { data: courseData, loading: courseLoading } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: {
            id: courseId,
        },
    });

    const [deleteTimeSlotMutation] = useMutation(GRAPHQL_MUTATION_DELETE_PEER_GROUP_TIMESLOT, {
        refetchQueries: [
            {
                query: GRAPHQL_QUERY_COURSE,
                variables: {
                    id: courseId,
                },
            },
        ],
    });

    const [addNewTimeSlotMutation] = useMutation(GRAPHQL_MUTATION_ADD_PEER_GROUP_TIMESLOT, {
        refetchQueries: [
            {
                query: GRAPHQL_QUERY_COURSE,
                variables: {
                    id: courseId,
                },
            },
        ],
    });

    const [moveUserToPeerGroupMutation] = useMutation(
        GRAPHQL_MUTATION_MOVE_USER_TO_PEER_GROUP_AS_ADMIN,
        {
            refetchQueries: [
                {
                    query: GRAPHQL_QUERY_ALL_PEER_GROUPS_FOR_COURSE,
                    variables: {
                        courseId: courseId,
                    },
                },
            ],
        },
    );

    if (!isAdmin) {
        return <Redirect to={routes.coursesDashboard()} />;
    }

    if (peerGroupsLoading || courseLoading || courseMembershipLoading) {
        return <LoadingPage />;
    }

    const peerGroups: PeerGroup[] = peerGroupData?.allPeerGroupsForCourse;
    const courseMemberships: CourseMembership[] =
        courseMembershipData?.allCourseMembershipsForCourse;
    const course: Course = courseData?.course;

    const courseMembershipsWithoutTimeSlots = courseMemberships.filter(
        (courseMembership) =>
            !courseMembership.selectedPeerGroupTimeSlots ||
            courseMembership.selectedPeerGroupTimeSlots.length === 0,
    );

    const assignedMemberIds = new Set(peerGroups.flatMap((group) => group.participantIds));

    const unassignedMembers = courseMemberships.filter(
        (courseMembership) => !assignedMemberIds.has(courseMembership.userId),
    );

    const timeSlotsWithUTCAndUserTimeZone =
        course.availablePeerGroupTimeSlots &&
        course.availablePeerGroupTimeSlots
            .map((timeSlot) => ({
                timeSlotUTC: timeSlot,
                timeSlotUserTimeZone: convertPeerGroupTimeSlotUTCToUserTimeZone(timeSlot, timezone),
            }))
            .sort((a, b) => {
                if (a.timeSlotUserTimeZone.weekday !== b.timeSlotUserTimeZone.weekday) {
                    return a.timeSlotUserTimeZone.weekday - b.timeSlotUserTimeZone.weekday;
                }
                return a.timeSlotUserTimeZone.startTimeInUserTimeZone.localeCompare(
                    b.timeSlotUserTimeZone.startTimeInUserTimeZone,
                );
            });

    const timeSlotSelectOptions = timeSlotsWithUTCAndUserTimeZone
        ? timeSlotsWithUTCAndUserTimeZone.map((timeSlot) => {
              const label = `${getDayFromWeekdayNumber(
                  timeSlot.timeSlotUserTimeZone.weekday,
              )}, ${timeSlot.timeSlotUserTimeZone.startTimeInUserTimeZone} ${timezoneOffset}`;

              return {
                  label,
                  value: getPeerGroupTimeSlotId(timeSlot.timeSlotUTC),
              };
          })
        : [];

    const handleOpenNewPeerGroup = async () => {
        if (!timeSlotsWithUTCAndUserTimeZone || !newPeerGroupTimeSlotId) {
            showSnackbar("Error: No time slots available", "error");
            return;
        }

        const selectedTimeSlot = timeSlotsWithUTCAndUserTimeZone.find(
            (timeSlot) => getPeerGroupTimeSlotId(timeSlot.timeSlotUTC) === newPeerGroupTimeSlotId,
        );

        if (!selectedTimeSlot) {
            showSnackbar("Error: Time slot not found", "error");
            return;
        }

        await openNewPeerGroupMutation({
            variables: {
                courseId,
                peerGroupTimeSlot: selectedTimeSlot.timeSlotUTC,
            },
        });

        setNewPeerGroupTimeSlotId(null);
        setOpenNewPeerGroupDialogOpen(false);
    };

    return (
        <PageWrapper removeMarginTop>
            <Typography variant="h4" sx={{ mb: 0, textAlign: "center" }}>
                Peer Group Admin Dashbard - {course.name}
            </Typography>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Course Sections Tabs"
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
                textColor="primary"
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: theme.palette.accentEarthy,
                        height: 4,
                        borderRadius: 1,
                    },
                }}
                sx={{
                    backgroundColor: theme.palette.neutralCool,
                    boxShadow: 1,
                    borderRadius: 1,
                    mt: 2,
                }}
            >
                <Tab
                    label="Summary"
                    id="tab-0"
                    aria-controls="tabpanel-0"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Peer Groups"
                    id="tab-1"
                    aria-controls="tabpanel-1"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Unassigned"
                    id="tab-2"
                    aria-controls="tabpanel-2"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
                <Tab
                    label="Time slots"
                    id="tab-3"
                    aria-controls="tabpanel-3"
                    sx={{
                        "&.Mui-selected": {
                            color: theme.palette.accentEarthy,
                        },
                    }}
                />
            </Tabs>

            {/* SUMMARY BOX */}
            <TabPanel value={tabValue} index={0}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralWarm,
                        borderRadius: 1,
                        p: 2,
                        mb: 4,
                    }}
                >
                    <Table sx={{ width: "100%", borderCollapse: "collapse" }}>
                        <TableBody>
                            {[
                                { label: "Total participants", value: courseMemberships.length },
                                { label: "Assigned participants", value: assignedMemberIds.size },
                                {
                                    label: "Unassigned participants",
                                    value: unassignedMembers.length,
                                },
                                {
                                    label: "Participants without time slots",
                                    value: courseMembershipsWithoutTimeSlots.length,
                                },
                                { label: "Peer group status", value: course.peerGroupStatus },
                                { label: "Peer groups", value: peerGroups.length },
                            ].map(({ label, value }, index) => (
                                <TableRow
                                    key={label}
                                    sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                                >
                                    <TableCell
                                        sx={{
                                            borderTop: "1px solid",
                                            borderBottom: "1px solid",
                                            borderRight: "1px solid",
                                            borderColor: "grey.300",
                                            py: 1,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 0, fontWeight: 600 }}>
                                            {label}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderTop: "1px solid",
                                            borderBottom: "1px solid",
                                            borderLeft: "1px solid",
                                            borderColor: "grey.300",
                                            py: 1,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 0 }}>
                                            {value}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Time zone for time display on the whole admin dashboard
                </Typography>
                <CSSelectField
                    options={IANATimezones}
                    value={timezone}
                    onChange={(value) => {
                        setTimezone(value);
                    }}
                    label="Time zone"
                    required
                />
            </TabPanel>

            {/* PEER GROUPS */}
            <TabPanel value={tabValue} index={1}>
                {peerGroups.length > 0 ? (
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                xs: "repeat(auto-fill, minmax(300px, 1fr))",
                                sm: "repeat(auto-fill, minmax(400px, 1fr))",
                            },
                            gap: 2,
                        }}
                    >
                        {peerGroups.map((peerGroup) => {
                            return (
                                <Box
                                    key={peerGroup.chatRoomUuid}
                                    sx={{
                                        backgroundColor: theme.palette.neutralWarm,
                                        borderRadius: 1,
                                        p: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mb: 1,
                                            alignItems: "center",
                                            flexDirection: { xs: "column", sm: "row" },
                                            gap: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 1,
                                                alignItems: { xs: "center", sm: "flex-start" },
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Chip
                                                label={peerGroup.id}
                                                sx={{
                                                    "& .MuiChip-label": {
                                                        textTransform: "none",
                                                    },
                                                }}
                                                size="small"
                                            />
                                            <Typography variant="h6" sx={{ mb: 0 }}>
                                                {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                                                    peerGroup.peerGroupTimeSlot,
                                                    timezone,
                                                    true,
                                                )}
                                            </Typography>
                                        </Box>
                                        <Chip
                                            label={`${peerGroup.participantIds.length} members`}
                                            size="medium"
                                            variant="green"
                                        />
                                    </Box>

                                    <Divider sx={{ my: 1 }} />
                                    {peerGroup.participants.map((participant) => (
                                        <Box key={participant.email}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        mb: 0,
                                                    }}
                                                    key={participant.email}
                                                >
                                                    {`${participant.name} - ${participant.email}`}
                                                </Typography>
                                                <Tooltip title="Move to another group">
                                                    <IconButton
                                                        sx={{ p: 0 }}
                                                        onClick={() => {
                                                            setUserIdToMoveToNewPeerGroup(
                                                                participant.id,
                                                            );
                                                            setMoveToPeerGroupDialogOpen(true);
                                                        }}
                                                    >
                                                        <ExitToAppIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                            <Divider sx={{ my: 0 }} />
                                        </Box>
                                    ))}
                                </Box>
                            );
                        })}
                        <Box
                            sx={{
                                backgroundColor: theme.palette.grey150,
                                borderRadius: 1,
                                p: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                gap: 2,
                            }}
                            onClick={() => setOpenNewPeerGroupDialogOpen(true)}
                        >
                            <Add sx={{ fontSize: theme.typography.pxToRem(35) }} />
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                Open new peer group
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="h4" sx={{ textAlign: "center", mt: 4 }}>
                        No peer groups created yet
                    </Typography>
                )}
            </TabPanel>

            {/* UNASSIGNED PARTICIPANTS */}
            <TabPanel value={tabValue} index={2}>
                <Box>
                    {unassignedMembers.map((courseMembership) => (
                        <Box
                            key={courseMembership.userId}
                            sx={{
                                backgroundColor: theme.palette.neutralWarm,
                                borderRadius: 1,
                                p: 2,
                                mb: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                            }}
                        >
                            <Box>
                                <Typography variant="h6" sx={{ mb: 0 }}>
                                    {courseMembership.userProfile?.fullName} -{" "}
                                    {courseMembership.userProfile?.email}
                                </Typography>
                                {courseMembership.selectedPeerGroupTimeSlots ? (
                                    courseMembership.selectedPeerGroupTimeSlots.map((timeSlot) => (
                                        <Typography
                                            variant="body1"
                                            sx={{ mb: 0 }}
                                            key={getPeerGroupTimeSlotId(timeSlot)}
                                        >
                                            {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                                                timeSlot,
                                                timezone,
                                                true,
                                            )}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant="body1" sx={{ mb: 0 }}>
                                        No time slots chosen
                                    </Typography>
                                )}
                            </Box>
                            {course.peerGroupStatus === PeerGroupStatus.GROUPS_ASSIGNED &&
                                peerGroups.length > 0 && (
                                    <Button
                                        variant="secondary"
                                        onClick={async () => {
                                            setUserIdToMoveToNewPeerGroup(courseMembership.userId);
                                            setMoveToPeerGroupDialogOpen(true);
                                        }}
                                        size="small"
                                    >
                                        Move to a peer group
                                    </Button>
                                )}
                        </Box>
                    ))}
                </Box>
            </TabPanel>

            {/* TIME SLOTS */}
            <TabPanel value={tabValue} index={3}>
                {course.peerGroupStatus === PeerGroupStatus.TIME_SLOT_SELECTION && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Changing time slots while the peer group status is "TIME_SLOT_SELECTION" can
                        impact how groups are formed. Please make sure there can still be enough
                        participants per time slot after adding or deleting time slots.
                    </Alert>
                )}
                {course.peerGroupStatus === PeerGroupStatus.GROUPS_ASSIGNED && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Changing time slots while the peer group status is "GROUPS_ASSIGNED" won't
                        have any impact, since groups are already formed.
                    </Alert>
                )}
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(auto-fill, minmax(300px, 1fr))",
                            sm: "repeat(auto-fill, minmax(400px, 1fr))",
                        },
                        gap: 2,
                    }}
                >
                    {timeSlotsWithUTCAndUserTimeZone &&
                        timeSlotsWithUTCAndUserTimeZone.map((timeSlot) => (
                            <Box
                                key={getPeerGroupTimeSlotId(timeSlot.timeSlotUTC)}
                                sx={{
                                    backgroundColor: theme.palette.neutralWarm,
                                    borderRadius: 1,
                                    p: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 0 }}>
                                    {formatPeerGroupTimeSlot(
                                        timeSlot.timeSlotUserTimeZone,
                                        timezone,
                                        true,
                                    )}
                                </Typography>
                                <Tooltip title="Delete time slot">
                                    <IconButton
                                        onClick={() => {
                                            setTimeSlotToDelete(timeSlot.timeSlotUTC);
                                            setConfirmationDialogOpen(true);
                                        }}
                                        size="small"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey150,
                            borderRadius: 1,
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            gap: 2,
                            fontWeight: 600,
                            fontSize: theme.typography.pxToRem(20),
                            "&:hover": {
                                backgroundColor: theme.palette.grey200,
                            },
                        }}
                        onClick={() => setNewTimeSlotDialogOpen(true)}
                    >
                        <Add sx={{ fontSize: theme.typography.pxToRem(35) }} /> Add time slot
                    </Box>
                </Box>
            </TabPanel>

            {/* DELETE TIME SLOT DIALOG */}
            <CSDialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Are you sure you want to delete this time slot?
                </Typography>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralWarm,
                        borderRadius: 1,
                        p: 2,
                        width: "fit-content",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 0 }}>
                        {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                            timeSlotToDelete,
                            timezone,
                            true,
                        )}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        variant="primary"
                        onClick={async () => {
                            await deleteTimeSlotMutation({
                                variables: {
                                    courseId,
                                    peerGroupTimeSlot: timeSlotToDelete,
                                },
                            });
                            setConfirmationDialogOpen(false);
                        }}
                    >
                        Delete
                    </Button>
                </Box>
            </CSDialog>
            {/* CREATE NEW TIME SLOT DIALOG */}
            <CSDialog open={newTimeSlotDialogOpen} onClose={() => setNewTimeSlotDialogOpen(false)}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Add a new time slot
                </Typography>
                <TextField
                    label="Week day"
                    select
                    fullWidth
                    value={newTimeSlotWeekday}
                    onChange={(e) =>
                        setNewTimeSlotWeekday(e.target.value as unknown as WeekdayNumbers)
                    }
                    sx={{ mb: 1 }}
                >
                    {[1, 2, 3, 4, 5, 6, 7].map((weekday) => (
                        <MenuItem key={weekday} value={weekday}>
                            {getDayFromWeekdayNumber(weekday as WeekdayNumbers)}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={`Start time in 24h format in ${timezoneOffset}`}
                    type="time"
                    fullWidth
                    value={newTimeSlotStartTime}
                    onChange={(e) =>
                        setNewTimeSlotStartTime(e.target.value as unknown as HourMinute24h)
                    }
                    sx={{ mb: 1 }}
                />
                <TextField
                    label="Cycle"
                    select
                    fullWidth
                    value={newTimeSlotCycle}
                    onChange={(e) =>
                        setNewTimeSlotCycle(e.target.value as unknown as PeerGroupCycle)
                    }
                    sx={{ mb: 1 }}
                >
                    {["biweekly", "weekly"].map((cycle) => (
                        <MenuItem key={cycle} value={cycle}>
                            {_.capitalize(cycle)}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    variant="primary"
                    onClick={async () => {
                        const timeSlotInUserTimeZone = {
                            weekday: newTimeSlotWeekday,
                            startTimeInUserTimeZone: newTimeSlotStartTime,
                            cycle: newTimeSlotCycle,
                        } as PeerGroupTimeSlotInUserTimeZone;

                        const timeSlotInUTC = convertPeerGroupTimeSlotUserTimeZoneToUTC(
                            timeSlotInUserTimeZone,
                            timezone,
                        );

                        const result = await addNewTimeSlotMutation({
                            variables: {
                                courseId,
                                peerGroupTimeSlot: timeSlotInUTC,
                            },
                        });

                        if (result.data.addPeerGroupTimeSlot) {
                            showSnackbar("Successfully added time slot", "success");
                        } else {
                            showSnackbar("Failed to add time slot. Please try again.", "error");
                        }

                        setNewTimeSlotDialogOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </CSDialog>
            {/* MOVE USER TO NEW PEER GROUP CONFIRMATION DIALOG */}
            <CSDialog
                open={moveToPeerGroupDialogOpen}
                onClose={() => setMoveToPeerGroupDialogOpen(false)}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Move user to new peer group
                </Typography>

                {userIdToMoveToNewPeerGroup &&
                    peerGroups.find((group) =>
                        group.participantIds.includes(userIdToMoveToNewPeerGroup),
                    ) && (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Moving a user to a new peer group will remove them from their current
                            peer group. They will stay in their old group chat though, and we will
                            open a new group chat with their new group. Please only do this with
                            caution.
                        </Alert>
                    )}

                <Typography variant="h6" sx={{ mb: 0 }}>
                    {(() => {
                        const membership = courseMemberships.find(
                            (membership) => membership.userId === userIdToMoveToNewPeerGroup,
                        );
                        return membership
                            ? `${membership.userProfile?.fullName} - ${membership.userProfile?.email}`
                            : "Error - User not found. Please reload the page.";
                    })()}
                </Typography>

                <Typography variant="body1">
                    {(() => {
                        const peerGroup =
                            userIdToMoveToNewPeerGroup &&
                            peerGroups.find((group) =>
                                group.participantIds.includes(userIdToMoveToNewPeerGroup),
                            );
                        if (peerGroup) {
                            const timeSlotInUserTimeZone =
                                convertPeerGroupTimeSlotUTCToUserTimeZone(
                                    peerGroup.peerGroupTimeSlot,
                                    timezone,
                                );
                            return `Current peer group: ${peerGroup.id} (${getDayFromWeekdayNumber(
                                timeSlotInUserTimeZone.weekday,
                            )}, ${timeSlotInUserTimeZone.startTimeInUserTimeZone} ${timezoneOffset}) - ${
                                peerGroup.participantIds.length
                            } members`;
                        }
                    })()}
                </Typography>

                <CSSelectField
                    options={peerGroups.map((peerGroup) => {
                        const timeSlotInUserTimeZone = convertPeerGroupTimeSlotUTCToUserTimeZone(
                            peerGroup.peerGroupTimeSlot,
                            timezone,
                        );

                        const label = `${peerGroup.id} (${getDayFromWeekdayNumber(timeSlotInUserTimeZone.weekday)}, ${timeSlotInUserTimeZone.startTimeInUserTimeZone} ${timezoneOffset}) - ${peerGroup.participantIds.length} members`;

                        return {
                            label,
                            value: peerGroup.id,
                        };
                    })}
                    value={chosenPeerGroupToMoveUserTo}
                    onChange={(value) => {
                        setChosenPeerGroupToMoveUserTo(value);
                    }}
                    label="Peer group"
                    required
                />

                <Button
                    variant="primary"
                    onClick={async () => {
                        const result = await moveUserToPeerGroupMutation({
                            variables: {
                                userId: userIdToMoveToNewPeerGroup,
                                peerGroupId: chosenPeerGroupToMoveUserTo,
                                courseId: courseId,
                            },
                        });
                        if (result.data) {
                            showSnackbar("Successfully moved member to new peer group", "success");
                        } else {
                            showSnackbar(
                                "Failed to move member to peer group. Please try again.",
                                "error",
                            );
                        }
                        setMoveToPeerGroupDialogOpen(false);
                        setUserIdToMoveToNewPeerGroup(null);
                        setChosenPeerGroupToMoveUserTo(null);
                    }}
                >
                    Confirm
                </Button>
            </CSDialog>

            {/* OPEN NEW PEER GROUP DIALOG */}
            <CSDialog
                open={openNewPeerGroupDialogOpen}
                onClose={() => setOpenNewPeerGroupDialogOpen(false)}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Open new peer group
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Please select a time slot for the new peer group.
                </Typography>
                <CSSelectField
                    options={timeSlotSelectOptions}
                    value={newPeerGroupTimeSlotId}
                    onChange={(value) => {
                        setNewPeerGroupTimeSlotId(value);
                    }}
                    label="Time slot"
                    required
                />
                <Button variant="primary" onClick={handleOpenNewPeerGroup}>
                    Confirm
                </Button>
            </CSDialog>
        </PageWrapper>
    );
};
