import { useQuery } from "@apollo/client";
import { Box, Divider, Typography } from "@mui/material";
import { GRAPHQL_QUERY_COURSE_MEMBERSHIP, GRAPHQL_QUERY_COURSE_PEER_GROUP } from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { BackArrowButton } from "components/BackArrowButton";
import PageWrapper from "components/PageWrapper";
import LoadingPage from "features/pages/LoadingPage";
import { DateTime } from "luxon";
import { Redirect, useParams } from "react-router";
import { LinkButton } from "features/navigation/LinkButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { PeerGroup } from "@app/shared/types";
import GroupsIcon from "@mui/icons-material/Groups";
import { convertAndFormatPeerGroupTimeSlotToUserTimeZone } from "./courseHelpers";
import { useSnackbar } from "components/SnackbarContext";
import { CSSelectField } from "components/CSSelectField";
import { useState } from "react";
import { IANATimezones } from "@app/shared/utils";

export const CoursePeerGroupPage = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const { showSnackbar } = useSnackbar();

    const [timezone, setTimezone] = useState(DateTime.local().zoneName);

    const { data: peerGroupData, loading: peerGroupLoading } = useQuery(
        GRAPHQL_QUERY_COURSE_PEER_GROUP,
        {
            fetchPolicy: "network-only",
            variables: {
                courseId: courseId,
            },
        },
    );

    const { data: courseMembershipData, loading: courseMembershipLoading } = useQuery(
        GRAPHQL_QUERY_COURSE_MEMBERSHIP,
        {
            fetchPolicy: "network-only",
            variables: {
                courseId: courseId,
            },
        },
    );

    const peerGroup: PeerGroup = peerGroupData?.coursePeerGroup;
    const courseMembership = courseMembershipData?.courseMembership;

    if (peerGroupLoading || courseMembershipLoading) {
        return <LoadingPage />;
    }

    if (peerGroup) {
        return (
            <PageWrapper removeMarginTop>
                <BackArrowButton route={routes.coursesDashboard()} />
                <Typography
                    variant="h2"
                    sx={{ mb: 0, display: "flex", alignItems: "center", gap: 2 }}
                >
                    <GroupsIcon
                        sx={{
                            fontSize: theme.typography.pxToRem(40),
                            color: theme.palette.accentEarthy,
                        }}
                    />{" "}
                    Your peer group
                </Typography>

                <Typography variant="body1">
                    Please coordinate with your group to schedule your first peer group meeting and
                    arrange your video conferencing solution."
                </Typography>

                <CSSelectField
                    options={IANATimezones}
                    value={timezone}
                    onChange={(value) => {
                        setTimezone(value);
                    }}
                    label="Time zone"
                    size="small"
                    required
                />

                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralWarm,
                        p: 3,
                        mt: 2,
                        borderRadius: 1,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center", gap: 1, fontWeight: 700 }}
                    >
                        <AccessTimeIcon />
                        {convertAndFormatPeerGroupTimeSlotToUserTimeZone(
                            peerGroup.peerGroupTimeSlot,
                            timezone,
                            true,
                        )}
                    </Typography>

                    <Divider sx={{ my: 1 }} />

                    <Typography variant="body1">
                        <strong> Group Members: </strong>
                        {peerGroup.participants.map(
                            (participant: { name: string; email: string }) => (
                                <Typography key={participant.email} variant="body1" sx={{ mb: 0 }}>
                                    {participant.name} - {participant.email}
                                </Typography>
                            ),
                        )}
                    </Typography>
                    <LinkButton
                        variant="primary"
                        openInNewTab
                        to={`https://community.banyantogether.com/messages/${peerGroup.chatRoomUuid}`}
                    >
                        Open group chat
                    </LinkButton>
                    <Typography variant="caption" sx={{ color: theme.palette.grey400 }}>
                        <br />
                        If you're not logged in to our course platform yet, your group chat won't
                        open automatically. In that case, once you've arrived on the course
                        platform, please click the 'Direct messages' button in the top right corner
                        of the page.
                    </Typography>
                </Box>
            </PageWrapper>
        );
    } else if (courseMembership && courseMembership.selectedPeerGroupTimeSlots) {
        return (
            <PageWrapper>
                <Typography variant="h3">
                    Thank you for confirming your availale time slots!
                </Typography>
                <Typography variant="body1">
                    We will let you know once we have assigned you to a peer group.
                </Typography>
                <LinkButton variant="primary" to={routes.coursesDashboard()}>
                    Go back to the dashboard
                </LinkButton>
            </PageWrapper>
        );
    } else {
        showSnackbar("You don't have access to this page", "error");
        return <Redirect to={routes.coursesDashboard()} />;
    }
};
