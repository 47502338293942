import { SanghaType, Session, SessionType } from "@app/shared/types";
import styled from "@emotion/styled";
import {
    CustomContentGenerator,
    DayHeaderContentArg,
    EventApi,
    MoreLinkArg,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { default as timeGridDayPlugin, default as timeGridPlugin } from "@fullcalendar/timegrid";
import { Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import debounce from "lodash/debounce";
import Tippy from "@tippyjs/react";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Instance } from "tippy.js";
import "tippy.js/dist/tippy.css";
import CommunityEventCard from "../community/CommunityEventCard";
import { getSessionOrSanghaTitle } from "../community/shared";
import { SESSIONS_PAGE_HEIGHT, SESSIONS_PAGE_HEIGHT_MOBILE } from "./SessionsPage";
import { SessionTypeColors, theme } from "app/theme";
import { isSanghaFromSessionFrozen } from "./sessionHelpers";
import { useUserTimezone } from "hooks/useUserTimezone";

interface EventCalendarViewProps {
    calendarRef: React.RefObject<FullCalendar>;
    events: Session[];
    serverTimeDelta: number;
    onSessionAttend: () => void;
    rsvpedSessions: { id: string }[];
    calendarView: CalendarViewType;
    setCalendarView: (view: CalendarViewType) => void;
    isPublicMentorshipPage?: boolean;
    frozenGroupIds?: string[];
}

export enum CalendarViewType {
    DAY = "timeGridDay",
    THREEDAYS = "timeGridThreeDays",
    WEEK = "timeGridWeek",
    MONTH = "dayGridMonth",
}

const StyleWrapper = styled.div<{ theme: Theme }>`
    .fc {
        color: ${(props) => props.theme.palette.grey700};
    }

    .fc-event {
        cursor: pointer;
        text-wrap: no-wrap;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }

    && .fc-button {
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.palette.grey200};
        color: ${(props) => props.theme.palette.grey800};
        border-radius: ${(props) => props.theme.borderRadius.large}px;
        padding: 4px 16px;
        height: fit-content;

        &:hover {
            background-color: ${(props) => props.theme.palette.grey100};
            color: ${(props) => props.theme.palette.grey800};
            border: 2px solid ${(props) => props.theme.palette.grey200};
        }

        &:focus,
        :not(:disabled):active:focus,
        :not(:disabled).fc-button-active:focus {
            box-shadow: none;
        }

        &:not(:disabled).fc-button-active,
        :not(:disabled):active {
            background-color: transparent;
            color: ${(props) => props.theme.palette.accentEarthy};
            border: 2px solid ${(props) => props.theme.palette.grey200};
            box-shadow: none;
        }

        &:disabled {
            background-color: transparent;
            color: ${(props) => props.theme.palette.grey400};
            border: 2px solid ${(props) => props.theme.palette.grey200};
        }
    }

    .fc-button-group {
        align-items: center;
    }

    .fc-timeGridThreeDays-button {
        text-wrap: nowrap;
    }

    && .fc-toolbar.fc-header-toolbar {
        padding: 4px 8px 4px 8px;
        margin-bottom: 0;
    }

    .fc-toolbar-chunk {
        display: flex;
        align-items: center;
    }

    && .fc-toolbar-title {
        text-transform: uppercase;
        color: ${(props) => props.theme.palette.grey800};
    }

    && .fc .fc-timegrid-slot-minor {
        border-top-style: none;
    }

    .fc-theme-standard .fc-scrollgrid {
        border-left: none;
    }

    && table tr th:first-child,
    && table tr td:first-child {
        border-top: none;
    }

    && table tr:first-child th {
        border-left: none;
        border-right: none;
    }

    && .fc .fc-day-today {
        background-color: ${(props) => props.theme.palette.neutralWhite};
    }

    .fc-col-header-cell.fc-day {
        padding: 6px;
    }

    .fc-timegrid-col-events {
        margin-right: 2px !important;
    }
    .fc-timegrid-axis-frame {
        height: 100%;
        justify-content: center !important;

        .timezoneLabel {
            font-size: 12px;
            color: ${(props) => props.theme.palette.grey700};
        }
    }

    && .fc-timegrid-now-indicator-line {
        border-top-width: 2px;
        border-top-color: ${(props) => props.theme.palette.error500};
        position: relative;
        z-index: 3;

        &:before {
            content: "";
            position: absolute;
            top: -6px;
            left: 0px;
            width: 10px;
            height: 10px;
            background-color: ${(props) => props.theme.palette.error500};
            border-radius: 50%;
        }
    }

    .fc-timegrid-now-indicator-arrow {
        display: none;
    }

    && .event-type-mfg-not-attending {
        background-color: ${SessionTypeColors[SessionType.INTEREST_GROUP].light} !important;
        border-color: ${SessionTypeColors[SessionType.INTEREST_GROUP].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.INTEREST_GROUP].default} !important;
        }
    }

    && .event-type-meditation-not-attending {
        background-color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].light} !important;
        border-color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].default} !important;
        }
    }

    && .event-type-talk-not-attending {
        background-color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].light} !important;
        border-color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].default} !important;
        }
    }

    && .event-type-silent-sit-not-attending {
        background-color: ${SessionTypeColors[SessionType.SILENT_SIT].light} !important;
        border-color: ${SessionTypeColors[SessionType.SILENT_SIT].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.SILENT_SIT].default} !important;
        }
    }

    && .event-type-welcome-session-not-attending {
        background-color: ${SessionTypeColors[SessionType.WELCOME_SESSION].light} !important;
        border-color: ${SessionTypeColors[SessionType.WELCOME_SESSION].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.WELCOME_SESSION].default} !important;
        }
    }

    && .event-type-qa-not-attending {
        background-color: ${SessionTypeColors[SessionType.QA_SESSION].light} !important;
        border-color: ${SessionTypeColors[SessionType.QA_SESSION].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.QA_SESSION].default} !important;
        }
    }

    .event-type-mfg-attending {
        background-color: ${SessionTypeColors[SessionType.INTEREST_GROUP].default} !important;
        border-color: ${SessionTypeColors[SessionType.INTEREST_GROUP].default} !important;
    }

    .event-type-meditation-attending {
        background-color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].default} !important;
        border-color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].default} !important;
    }

    .event-type-talk-attending {
        background-color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].default} !important;
        border-color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].default} !important;
    }

    .event-type-silent-sit-attending {
        background-color: ${SessionTypeColors[SessionType.SILENT_SIT].default} !important;
        border-color: ${SessionTypeColors[SessionType.SILENT_SIT].default} !important;
    }

    .event-type-welcome-session-attending {
        background-color: ${SessionTypeColors[SessionType.WELCOME_SESSION].default} !important;
        border-color: ${SessionTypeColors[SessionType.WELCOME_SESSION].default} !important;
    }

    .event-type-qa-attending {
        background-color: ${SessionTypeColors[SessionType.QA_SESSION].default} !important;
        border-color: ${SessionTypeColors[SessionType.QA_SESSION].default} !important;
    }

    .event-type-sangha-attending {
        background-color: ${SessionTypeColors[SessionType.SANGHA_SESSION].default} !important;
        border-color: ${SessionTypeColors[SessionType.SANGHA_SESSION].default} !important;
    }

    .event-type-sangha-not-attending {
        background-color: ${SessionTypeColors[SessionType.SANGHA_SESSION].light} !important;
        border-color: ${SessionTypeColors[SessionType.SANGHA_SESSION].default} !important;
        .fc-event-main {
            color: ${SessionTypeColors[SessionType.SANGHA_SESSION].default} !important;
        }
    }

    .event-type-locked-sangha {
        background-color: ${SessionTypeColors[SessionType.SANGHA_SESSION].light} !important;
        border-color: ${theme.palette.grey700} !important;
        .fc-event-main {
            color: ${theme.palette.grey700} !important;
        }
    }

    .fc-daygrid-day-events {
        color: ${(props) => props.theme.palette.neutralWhite};
        & .event-type-mfg-not-attending {
            color: ${SessionTypeColors[SessionType.INTEREST_GROUP].default};
            border: 1px solid ${SessionTypeColors[SessionType.INTEREST_GROUP].default};
        }
        & .event-type-meditation-not-attending {
            color: ${SessionTypeColors[SessionType.COMMUNITY_SIT].default};
            border: 1px solid ${SessionTypeColors[SessionType.COMMUNITY_SIT].default};
        }
        & .event-type-talk-not-attending {
            color: ${SessionTypeColors[SessionType.COMMUNITY_TALK].default};
            border: 1px solid ${SessionTypeColors[SessionType.COMMUNITY_TALK].default};
        }
        & .event-type-silent-sit-not-attending {
            color: ${SessionTypeColors[SessionType.SILENT_SIT].default};
            border: 1px solid ${SessionTypeColors[SessionType.SILENT_SIT].default};
        }
        & .event-type-welcome-session-not-attending {
            color: ${SessionTypeColors[SessionType.WELCOME_SESSION].default};
            border: 1px solid ${SessionTypeColors[SessionType.WELCOME_SESSION].default};
        }
        & .event-type-qa-not-attending {
            color: ${SessionTypeColors[SessionType.QA_SESSION].default};
            border: 1px solid ${SessionTypeColors[SessionType.QA_SESSION].default};
        }
        & .event-type-sangha-not-attending {
            color: ${SessionTypeColors[SessionType.SANGHA_SESSION].default};
            border: 1px solid ${SessionTypeColors[SessionType.SANGHA_SESSION].default};
        }
        & .event-type-locked-sangha {
            color: ${theme.palette.grey700};
            border: 1px solid ${theme.palette.grey700};
        }
        & .event-cancelled {
            color: ${(props) => props.theme.palette.grey300};
            border: 1px solid ${(props) => props.theme.palette.grey300};
            text-decoration: line-through;
        }
        & .past-event {
            color: ${(props) => props.theme.palette.grey700};
            border: 1px solid ${(props) => props.theme.palette.grey300};
            cursor: default;
            pointer-events: none;
        }
    }

    && .past-event {
        cursor: default;
        pointer-events: none;
        border-color: ${(props) => props.theme.palette.grey300} !important;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
        }
        .fc-event-main {
            color: ${(props) => props.theme.palette.grey400} !important;
        }
    }

    && .event-cancelled {
        background-color: ${(props) => props.theme.palette.grey100} !important;
        border-color: ${(props) => props.theme.palette.grey300} !important;
        text-decoration: line-through;

        .fc-event-main {
            color: ${(props) => props.theme.palette.grey300} !important;
        }
    }

    && .fc-col-header-cell.fc-day {
        height: 49px;
    }

    .fc-daygrid-day-top {
        justify-content: center;
    }

    .fc-daygrid-day-number {
        font-size: 12px;
    }

    .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
        background-color: ${(props) => props.theme.palette.accentEarthy};
        color: ${(props) => props.theme.palette.neutralWhite};
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px 4px 8px;
        margin-top: 2px;
        margin-bottom: 3px;
    }

    .fc-daygrid-more-link.fc-more-link {
        color: ${(props) => props.theme.palette.primaryLeaves};
    }

    && .fc-next-button,
    && .fc-prev-button {
        height: 39px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
    }

    .fc-timegrid-event .fc-event-main {
        padding: 2px 0px 0px 6px;
    }

    .tippy-box {
        border-radius: ${(props) => props.theme.borderRadius.default}px;
        width: 400px;
        background-color: transparent;
    }

    .tippy-content {
        padding: 0px !important;
    }

    .fc-timegrid-slot-label {
        font-size: 12px;
        position: relative;
        transform: translateY(-50%);
    }

    .fc-timegrid-slot-label[data-time="00:00:00"] {
        display: none;
    }

    .fc-event-selected::after,
    .fc-event:focus::after {
        background: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        && .fc-toolbar-title {
            font-size: 20px;
        }
        && .tippy-box {
            width: 100vw;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        && [data-tippy-root] {
            width: 100vw !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            height: ${(props) => SESSIONS_PAGE_HEIGHT_MOBILE} !important;
            transform: none !important;
        }

        && .tippy-content {
            height: 100%;
            > div {
                height: 100%;
            }
        }

        && .fc-event,
        && .fc-event-main,
        && .fc-timegrid-event && .fc,
        && .fc-col-header-cell.fc-day,
        && .fc-daygrid-day-events {
            font-size: 12px !important;
            text-wrap: wrap;
            word-break: break-word;
            padding: 0px 0px 0px 1px;
        }

        && .fc-next-button,
        && .fc-prev-button {
            height: 30px;
            padding: 0px 8px;
        }

        && .fc .fc-button .fc-icon {
            font-size: 20px;
        }

        && .fc-toolbar.fc-header-toolbar {
            margin-bottom: 0;
        }
    }
`;

const DayCircle = styled.div<{ theme: Theme }>`
    background-color: ${(props) => props.theme.palette.accentEarthy};
    color: ${(props) => props.theme.palette.neutralWhite};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 18px;
        height: 18px;
        margin-left: 2px;
    }
`;

export const CalendarView = (props: EventCalendarViewProps) => {
    const {
        calendarRef,
        events,
        serverTimeDelta,
        onSessionAttend,
        rsvpedSessions = [],
        calendarView,
        setCalendarView,
        isPublicMentorshipPage,
        frozenGroupIds,
    } = props;

    const theme = useTheme();
    const timezone = useUserTimezone();

    const tippyInstanceRef = useRef<Instance | null>(null);
    const styleWrapperRef = useRef<HTMLDivElement>(null);

    const isMobileView = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true,
    });

    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [isStyleWrapperRefAssigned, setIsStyleWrapperRefAssigned] = useState(false);

    const getCalendarApi = () => {
        return calendarRef.current?.getApi();
    };

    const changeCalendarView = (
        viewType:
            | CalendarViewType.MONTH
            | CalendarViewType.WEEK
            | CalendarViewType.THREEDAYS
            | CalendarViewType.DAY,
    ) => {
        const calendarApi = getCalendarApi();
        calendarApi?.changeView(viewType);
        setCalendarView(viewType);
    };

    const getCurrentView = () => {
        const calendarApi = getCalendarApi();
        return calendarApi?.view.type as
            | CalendarViewType.MONTH
            | CalendarViewType.WEEK
            | CalendarViewType.THREEDAYS
            | CalendarViewType.DAY;
    };

    const handleUpdateView = () => {
        setCalendarView(getCurrentView());
        visibleTippyInstanceRef.current = null;

        const calendarApi = getCalendarApi();
        const calendarDate = calendarApi?.getDate();

        setCurrentDate(calendarDate || new Date());
    };

    const handleMoreLinkClick = (info: MoreLinkArg) => {
        const calendarApi = info.view.calendar;
        calendarApi.changeView(CalendarViewType.DAY, info.date);
        changeCalendarView(CalendarViewType.DAY);
    };

    useEffect(() => {
        const currentView = getCurrentView();

        if (currentView) {
            if (isMobileView && currentView === CalendarViewType.WEEK) {
                changeCalendarView(CalendarViewType.THREEDAYS);
            } else if (!isMobileView && currentView === CalendarViewType.THREEDAYS) {
                changeCalendarView(CalendarViewType.WEEK);
            } else {
                setCalendarView(currentView);
            }
        }

        if (styleWrapperRef.current) {
            setIsStyleWrapperRefAssigned(true);
        }

        renderTimezone();
    }, [isMobileView]);

    useEffect(() => {
        if (!styleWrapperRef.current) {
            return;
        }

        const debouncedResize = debounce(() => {
            calendarRef.current?.getApi().updateSize();
        }, 50);

        const resizeObserver = new ResizeObserver(() => {
            debouncedResize();
        });

        resizeObserver.observe(styleWrapperRef.current);

        return () => {
            resizeObserver.disconnect();
            debouncedResize.cancel();
        };
    }, []);

    const createCalendarEvents = (eventsArray: Session[]) => {
        return eventsArray.map((event: Session) => {
            const userIsAttending = rsvpedSessions.some(
                (session: { id: string }) => session.id === event.id,
            );

            let className = "";
            switch (event.type) {
                case SessionType.INTEREST_GROUP:
                    if ("sanghaMembership" in event) {
                        className = event.sanghaMembership
                            ? "event-type-mfg-attending"
                            : "event-type-mfg-not-attending";
                    }
                    break;
                case SessionType.COMMUNITY_SIT:
                    className = userIsAttending
                        ? "event-type-meditation-attending"
                        : "event-type-meditation-not-attending";
                    break;
                case SessionType.COMMUNITY_TALK:
                    className = userIsAttending
                        ? "event-type-talk-attending"
                        : "event-type-talk-not-attending";
                    break;
                case SessionType.SILENT_SIT:
                    className = userIsAttending
                        ? "event-type-silent-sit-attending"
                        : "event-type-silent-sit-not-attending";
                    break;
                case SessionType.WELCOME_SESSION:
                    className = userIsAttending
                        ? "event-type-welcome-session-attending"
                        : "event-type-welcome-session-not-attending";
                    break;
                case SessionType.QA_SESSION:
                    className = userIsAttending
                        ? "event-type-qa-attending"
                        : "event-type-qa-not-attending";
                    break;
                case SessionType.SANGHA_SESSION:
                    if (userIsAttending) {
                        className = "event-type-sangha-attending";
                    } else if (isSanghaFromSessionFrozen(event, frozenGroupIds)) {
                        className = "event-type-locked-sangha";
                    } else {
                        className = "event-type-sangha-not-attending";
                    }
                    break;
                default:
                    className = userIsAttending
                        ? "event-type-default-attending"
                        : "event-type-default-not-attending";
                    break;
            }

            if (event.cancelled) {
                className += " event-cancelled";
            }

            if (DateTime.fromISO(event.endTime) < DateTime.now()) {
                className += " past-event";
            }

            const calendarEvent = {
                title: getSessionOrSanghaTitle(event, event.sangha),
                start: new Date(event.time),
                end: new Date(event.endTime),
                className: className,
                extendedProps: {
                    eventId: event.id,
                    sessionObject: event,
                },
            };

            return calendarEvent;
        });
    };

    const calendarEvents = createCalendarEvents(events);

    const renderEventContent = ({ event, view }: { event: EventApi; view: any }) => {
        const start = DateTime.fromJSDate(event.start as Date);
        const end = DateTime.fromJSDate(event.end as Date);
        const duration = end.diff(start, "minutes").minutes;
        const startTime = start.toFormat(start.minute === 0 ? "ha" : "h:mma").toLowerCase();

        if (view.type === CalendarViewType.MONTH) {
            // Month view logic: single row
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                        gap: "4px",
                        padding: 1,
                        width: "100%",
                    }}
                    data-testid="event-content"
                >
                    <Typography
                        variant="body3"
                        noWrap
                        style={{
                            overflow: "hidden",
                            textOverflow: "clip",
                            display: "flex",
                            gap: 0.25,
                        }}
                        sx={{ mb: 0 }}
                    >
                        {isSanghaFromSessionFrozen(
                            event.extendedProps.sessionObject,
                            frozenGroupIds,
                        ) && (
                            <span
                                className="material-symbols-rounded"
                                style={{
                                    fontSize: theme.typography.pxToRem(14),
                                    color: theme.palette.grey700,
                                }}
                            >
                                lock
                            </span>
                        )}
                        <strong>{event.title}</strong> {startTime}
                    </Typography>
                </div>
            );
        } else {
            // Week and day view logic: dynamic layout
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: `${duration > 30 ? "column" : "row"}`,
                        justifyContent: "flex-start",
                        height: "100%",
                        gap: `${duration > 30 ? 0 : 4}px`,
                    }}
                    data-testid="event-content"
                >
                    <Typography variant="body3" sx={{ mb: 0, display: "flex", gap: 0.25 }}>
                        {isSanghaFromSessionFrozen(
                            event.extendedProps.sessionObject,
                            frozenGroupIds,
                        ) && (
                            <span
                                className="material-symbols-rounded"
                                style={{
                                    fontSize: theme.typography.pxToRem(14),
                                    color: theme.palette.grey700,
                                }}
                            >
                                lock
                            </span>
                        )}
                        <strong>{event.title}</strong>
                        {duration > 30 && <br />}
                    </Typography>

                    {!isMobileView && (
                        <Typography variant="body3" sx={{ mb: 0 }}>
                            {startTime}
                        </Typography>
                    )}
                </div>
            );
        }
    };

    const dayHeaderContent: CustomContentGenerator<DayHeaderContentArg> = (arg) => {
        const date = DateTime.fromJSDate(arg.date);
        const isToday = date.hasSame(DateTime.local(), "day");

        if (isToday) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: isMobileView ? "column" : "row",
                        marginTop: -2,
                        marginRight: -9,
                    }}
                >
                    <span style={{ color: theme.palette.accentEarthy, fontWeight: "bold" }}>
                        {date.toFormat("ccc")}
                    </span>
                    <DayCircle theme={theme}>{date.day}</DayCircle>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: isMobileView ? 0 : 4,
                        flexDirection: isMobileView ? "column" : "row",
                    }}
                >
                    <span>{date.toFormat("ccc")}</span>
                    <span>{date.toFormat("d")}</span>
                </div>
            );
        }
    };

    const renderDayCellContent = (arg: any) => {
        if (!(getCurrentView() === CalendarViewType.MONTH)) {
            return;
        }

        // if it's the first day of the month, display the month name
        if (arg.date.getDate() === 1) {
            return `${arg.date.toLocaleString("default", { month: "long" })} ${arg.date.getDate()}`;
        } else {
            return arg.date.getDate();
        }
    };

    const renderTimezone = () => {
        const axisCellFrame = document.querySelector(".fc-timegrid-axis-frame");

        if (axisCellFrame && axisCellFrame.querySelector(".timezoneLabel") === null) {
            const customContent = document.createElement("span");
            customContent.className = "timezoneLabel";
            customContent.innerHTML = DateTime.local().offsetNameShort;
            axisCellFrame.appendChild(customContent);
        }
    };

    const visibleTippyInstanceRef = useRef(null);

    const lastClickedEventRef = useRef<HTMLElement | null>(null);
    const clickHandlers = new Map();

    return (
        <StyleWrapper theme={theme} ref={styleWrapperRef} style={{ height: "100%" }}>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, timeGridDayPlugin]}
                initialView={calendarView}
                initialDate={currentDate}
                events={calendarEvents}
                fixedWeekCount={false}
                height={isMobileView ? SESSIONS_PAGE_HEIGHT_MOBILE : SESSIONS_PAGE_HEIGHT}
                headerToolbar={
                    isMobileView
                        ? {
                              left: "title",
                              right: "prev,next",
                          }
                        : {
                              left: "today prev,next",
                              center: "title",
                              right: "timeGridDay,timeGridWeek,dayGridMonth",
                          }
                }
                buttonText={{
                    today: "Today",
                    timeGridThreeDays: "3 Days",
                    timeGridDay: "Day",
                    timeGridWeek: "Week",
                    dayGridMonth: "Month",
                }}
                eventColor={theme.palette.neutralWarm}
                titleFormat={{ month: "long", year: "numeric" }}
                views={{
                    timeGridWeek: {
                        dayHeaderContent: dayHeaderContent,
                    },
                    timeGridThreeDays: {
                        type: "timeGrid",
                        dayCount: 3,
                        dayHeaderContent: dayHeaderContent,
                    },
                    timeGridDay: {
                        dayHeaderContent: dayHeaderContent,
                    },
                }}
                allDaySlot={false}
                nowIndicator={true}
                dayMaxEvents={4}
                firstDay={1}
                eventContent={(args) => {
                    return (
                        isStyleWrapperRefAssigned && (
                            <Tippy
                                key={args.event.extendedProps.eventId}
                                content={
                                    <CommunityEventCard
                                        event={args.event.extendedProps.sessionObject}
                                        timezone={timezone}
                                        hasCloseButton
                                        onCardClose={() => {
                                            if (tippyInstanceRef.current) {
                                                tippyInstanceRef.current.hide();
                                                visibleTippyInstanceRef.current = null;
                                            }
                                            if (lastClickedEventRef.current) {
                                                lastClickedEventRef.current.style.boxShadow =
                                                    "none";
                                            }
                                        }}
                                        onAttend={onSessionAttend}
                                        addShadow
                                        serverTimeDelta={serverTimeDelta}
                                        userRsvpedToSession={rsvpedSessions?.some(
                                            (rsvpedSession) =>
                                                rsvpedSession.id ===
                                                args.event.extendedProps.sessionObject.id,
                                        )}
                                        isPublic={isPublicMentorshipPage}
                                        isPublicMentorshipPage={isPublicMentorshipPage}
                                        showSanghaCard={
                                            args.event.extendedProps.sessionObject.sangha?.type ===
                                            SanghaType.MentorshipGroup
                                        }
                                        showSpecificDate
                                        sangha={args.event.extendedProps.sessionObject.sangha}
                                        isFrozenGroup={isSanghaFromSessionFrozen(
                                            args.event.extendedProps.sessionObject,
                                            frozenGroupIds,
                                        )}
                                        isCalendarCard
                                    />
                                }
                                trigger="click"
                                interactive={true}
                                placement="auto"
                                arrow={false}
                                maxWidth={isMobileView ? "100vw" : 400}
                                appendTo={styleWrapperRef.current || document.body}
                                className="tippy-box"
                                zIndex={1101}
                                popperOptions={{
                                    modifiers: [
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                boundary: "viewport",
                                            },
                                        },
                                        {
                                            name: "flip",
                                            options: {
                                                fallbackPlacements: [
                                                    "left",
                                                    "right",
                                                    "top",
                                                    "bottom",
                                                ],
                                            },
                                        },
                                    ],
                                }}
                                onTrigger={(instance) => {
                                    tippyInstanceRef.current = instance;
                                    visibleTippyInstanceRef.current =
                                        args.event.extendedProps.eventId;
                                }}
                                onUntrigger={() => {
                                    visibleTippyInstanceRef.current = null;
                                }}
                                onAfterUpdate={(instance) => {
                                    if (
                                        args.event.extendedProps.eventId ===
                                        visibleTippyInstanceRef.current
                                    ) {
                                        if (tippyInstanceRef.current) {
                                            tippyInstanceRef.current.hide();
                                        }
                                        instance.show();
                                        tippyInstanceRef.current = instance;
                                    }
                                }}
                                onHide={() => {
                                    if (lastClickedEventRef.current) {
                                        lastClickedEventRef.current.style.boxShadow = "none";
                                    }
                                }}
                            >
                                {renderEventContent(args)}
                            </Tippy>
                        )
                    );
                }}
                moreLinkClick={handleMoreLinkClick}
                dayCellContent={renderDayCellContent}
                ref={calendarRef}
                datesSet={handleUpdateView}
                windowResizeDelay={300}
                eventDidMount={(info) => {
                    const handleClick = () => {
                        if (lastClickedEventRef.current) {
                            lastClickedEventRef.current.style.boxShadow = "none";
                        }

                        info.el.style.boxShadow = theme.shadow.default;
                        lastClickedEventRef.current = info.el;
                    };

                    info.el.addEventListener("click", handleClick);
                    clickHandlers.set(info.el, handleClick);
                }}
                eventWillUnmount={(info) => {
                    const handleClick = clickHandlers.get(info.el);

                    if (handleClick) {
                        info.el.removeEventListener("click", handleClick);
                    }

                    clickHandlers.delete(info.el);
                }}
            />
        </StyleWrapper>
    );
};
