import { Avatar, Box, Button, Chip, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { Course, PeerGroupStatus, UserCourseStatus } from "@app/shared/types";
import { DateTime } from "luxon";
import { routes } from "app/routes";
import { useSelector } from "react-redux";
import { selectCanAccessMemberZone } from "features/auth/auth";
import _ from "lodash";
import { LinkButton } from "features/navigation/LinkButton";
import { useQuery } from "@apollo/client";
import { GRAPHQL_QUERY_COURSE_PEER_GROUP } from "app/queries";
import { CircularProgressContainer } from "./CircularProgressContainer";

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.grey100,
        borderRadius: theme.borderRadius.default,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    cardContent: {
        color: theme.palette.grey800,
        padding: theme.spacing(1, 3, 3, 3),
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%",
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "auto",
    },
    statusText: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey700,
    },
    inProgress: {
        color: theme.palette.accentEarthy,
        fontWeight: theme.typography.fontWeightBold,
    },
    completed: {
        color: theme.palette.success500,
        fontWeight: theme.typography.fontWeightBold,
    },
    accessCourseButton: {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: 4,
        "&:hover": {
            textDecoration: "none",
        },
    },
    buttonText: {
        textDecoration: "underline",
    },
    buttonIcon: {
        textDecoration: "none",
    },
    courseImage: {
        width: "100%",
        height: 180,
        objectFit: "cover",
        borderTopLeftRadius: theme.borderRadius.default,
        borderTopRightRadius: theme.borderRadius.default,
    },
}));

const renderTeachers = (course: Course) => {
    const teachers = course.teachers;
    return (
        <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }} key={course.id}>
            <Avatar src={teachers[0]?.picture || undefined} sx={{ width: 24, height: 24, mr: 1 }} />
            <Typography variant="caption" sx={{ mb: 0 }}>
                With {teachers[0]?.name}
                {teachers.length > 1 && ` and +${teachers.length - 1}`}
            </Typography>
        </Box>
    );
};

export const CourseCard = (props: { course: Course }) => {
    const { course } = props;
    const classes = useStyles(props);

    const isBanyanMember = useSelector(selectCanAccessMemberZone);
    const hasDifferentPriceForMembers = _.isNumber(course.priceForMembers);
    const showMemberPrice = isBanyanMember && hasDifferentPriceForMembers;
    const coursePrice = showMemberPrice ? (course.priceForMembers as number) : course.price;

    const { data: peerGroupData, loading: peerGroupDataLoading } = useQuery(
        GRAPHQL_QUERY_COURSE_PEER_GROUP,
        {
            fetchPolicy: "network-only",
            variables: {
                courseId: course.id,
            },
        },
    );

    const peerGroup = peerGroupData?.coursePeerGroup;

    const renderPeerGroupButton = () => {
        if (peerGroupDataLoading) {
            return <CircularProgressContainer size={24} sx={{ mt: 1 }} />;
        } else if (peerGroup) {
            return (
                <LinkButton
                    to={routes.coursePeerGroup(course.id)}
                    variant="secondary"
                    size="small"
                    sx={{ mt: 2 }}
                >
                    See peer group details
                </LinkButton>
            );
        } else if (
            course.myCourseMembership &&
            !course.myCourseMembership.selectedPeerGroupTimeSlots &&
            course.peerGroupStatus === PeerGroupStatus.TIME_SLOT_SELECTION
        ) {
            return (
                <LinkButton
                    to={routes.coursePeerGroupSelection(course.id)}
                    variant="primary"
                    sx={{ mt: 2 }}
                >
                    Select peer group time slots
                </LinkButton>
            );
        } else if (
            course.myCourseMembership &&
            !peerGroup &&
            course.peerGroupStatus === PeerGroupStatus.GROUPS_ASSIGNED
        ) {
            return (
                <LinkButton
                    to={routes.joinExistingPeerGroupPage(course.id)}
                    variant="primary"
                    sx={{ mt: 2 }}
                >
                    Join peer group
                </LinkButton>
            );
        }
    };

    const renderButton = () => {
        return course.myCourseMembership ? (
            <Button
                className={classes.accessCourseButton}
                variant="tertiary"
                href={course.url}
                data-testid="accessCourseButton"
            >
                <span className={classes.buttonText}>Access Course</span>{" "}
                <span className={classNames("material-symbols-rounded", classes.buttonIcon)}>
                    open_in_new
                </span>
            </Button>
        ) : (
            <Button
                variant="tertiary"
                href={routes.courseEmbeddedLandingPage(course.id)}
                data-testid="knowMoreButton"
            >
                Know more
            </Button>
        );
    };

    const renderStatusOrPrice = () => {
        if (course?.myCourseMembership) {
            if (course.myCourseMembership.status === UserCourseStatus.COMPLETED) {
                return (
                    <Typography
                        className={classNames(classes.statusText, classes.completed)}
                        variant="caption"
                        data-testid="courseStatus"
                    >
                        Completed
                    </Typography>
                );
            } else if (course.myCourseMembership.status === UserCourseStatus.IN_PROGRESS) {
                return (
                    <Typography
                        className={classNames(classes.statusText, classes.inProgress)}
                        variant="caption"
                        data-testid="courseStatus"
                    >
                        In progress
                    </Typography>
                );
            } else if (course.myCourseMembership.status === UserCourseStatus.OPTED_OUT) {
                return (
                    <Typography
                        className={classes.statusText}
                        variant="caption"
                        data-testid="courseStatus"
                    >
                        Opted out
                    </Typography>
                );
            }
        } else {
            if (coursePrice > 0) {
                return (
                    <Chip
                        label={`$${coursePrice}`}
                        variant="darkGreen"
                        sx={{ fontSize: "1rem" }}
                        data-testid="coursePrice"
                    />
                );
            } else {
                return (
                    <Typography
                        className={classes.statusText}
                        variant="caption"
                        data-testid="courseStatus"
                    >
                        Not started
                    </Typography>
                );
            }
        }
    };

    const isCourseMaxOneMonthOld = (course: Course) => {
        const createdDate = DateTime.fromISO(course.createdDate as unknown as string);
        return createdDate && createdDate.plus({ months: 1 }) > DateTime.local();
    };

    const showPeerGroupButton =
        course.peerGroupStatus === PeerGroupStatus.TIME_SLOT_SELECTION ||
        course.peerGroupStatus === PeerGroupStatus.GROUPS_ASSIGNED;

    return (
        <div key={course.id} className={classes.card}>
            <img src={course.imageUrl} className={classes.courseImage} alt={course.name} />
            {!course?.myCourseMembership && isCourseMaxOneMonthOld(course) && (
                <Chip
                    label="New"
                    variant="green"
                    sx={{ position: "absolute", top: 20, left: 20 }}
                />
            )}
            <div className={classes.cardContent}>
                <Typography variant="h3" data-testid="courseTitle" sx={{ mb: 0 }}>
                    {course.name}
                </Typography>
                <Typography
                    variant="body1"
                    data-testid="courseDescription"
                    sx={{ mb: 2, flexGrow: 1 }}
                >
                    {course.description}
                </Typography>

                {course.teachers.length > 0 && renderTeachers(course)}

                <div className={classes.cardFooter}>
                    {renderButton()}
                    {renderStatusOrPrice()}
                </div>
                {showPeerGroupButton && renderPeerGroupButton()}
            </div>
        </div>
    );
};

export default CourseCard;
