import { useMutation, useQuery } from "@apollo/client";
import { getPeerGroupTimeSlotId, IANATimezones } from "@app/shared/utils";
import { Alert, Box, Button, Chip, Tooltip, Typography, useMediaQuery } from "@mui/material";
import {
    GRAPHQL_MUTATION_SAVE_COURSE_PEER_GROUP_TIME_SLOTS,
    GRAPHQL_QUERY_COURSE,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { BackArrowButton } from "components/BackArrowButton";
import { CSDialog } from "components/CSDialog";
import { CSSelectField } from "components/CSSelectField";
import PageWrapper from "components/PageWrapper";
import { useSnackbar } from "components/SnackbarContext";
import { selectUser } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import {
    convertPeerGroupTimeSlotUTCToUserTimeZone,
    formatPeerGroupTimeSlot,
} from "./courseHelpers";
import { Course, PeerGroupStatus, PeerGroupTimeSlot } from "@app/shared/types";
import { GenericErrorPage } from "components/GenericErrorPage";
import { useQueryParams } from "hooks/useQueryParams";

export const PeerGroupSelectionPage = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const query = useQueryParams();

    const user = useSelector(selectUser);
    const navigationHistory = useHistory();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { showSnackbar } = useSnackbar();

    const [timezone, setTimezone] = useState(user?.timeZone || DateTime.local().zoneName);
    const [hasUserSelectedTimezone, setHasUserSelectedTimezone] = useState(false);
    const [selectedTimeSlotIds, setSelectedTimeSlotIds] = useState<string[]>([]);
    const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

    const hasSelectedEnoughTimeSlots = selectedTimeSlotIds.length >= 2;

    const [saveSelectedPeerGroupTimeSlots] = useMutation(
        GRAPHQL_MUTATION_SAVE_COURSE_PEER_GROUP_TIME_SLOTS,
    );

    const {
        data: courseData,
        loading: courseLoading,
        error: courseError,
    } = useQuery(GRAPHQL_QUERY_COURSE, {
        variables: {
            id: courseId,
        },
    });

    if (courseLoading) {
        return <LoadingPage />;
    }

    const course: Course = courseData?.course;

    if (courseError) {
        return <GenericErrorPage />;
    }

    if (
        !course?.myCourseMembership ||
        !course?.peerGroupStatus ||
        course?.peerGroupStatus !== PeerGroupStatus.TIME_SLOT_SELECTION
    ) {
        showSnackbar("You don't have access to this page", "error");
        return <Redirect to={routes.coursesDashboard()} />;
    }

    if (!course.availablePeerGroupTimeSlots) {
        showSnackbar("No peer group time slots available", "error");
        return <Redirect to={routes.coursesDashboard()} />;
    }

    const userHasAlreadySelectedPeerGroupTimeSlots =
        course.myCourseMembership?.selectedPeerGroupTimeSlots &&
        course.myCourseMembership.selectedPeerGroupTimeSlots.length > 0;

    const allowSwitchingTimeSlots = query.allowSwitching === "true";

    if (userHasAlreadySelectedPeerGroupTimeSlots && !allowSwitchingTimeSlots) {
        showSnackbar("You have already selected your peer group time slots", "error");
        return <Redirect to={routes.coursesDashboard()} />;
    }

    // We use the user time zone for sorting and in the frontend, but query and save the time slots in UTC
    const timeSlotsWithUTCAndUserTimeZone = course.availablePeerGroupTimeSlots
        .map((timeSlot) => ({
            timeSlotUTC: timeSlot,
            timeSlotUserTimeZone: convertPeerGroupTimeSlotUTCToUserTimeZone(timeSlot, timezone),
        }))
        .sort((a, b) => {
            if (a.timeSlotUserTimeZone.weekday !== b.timeSlotUserTimeZone.weekday) {
                return a.timeSlotUserTimeZone.weekday - b.timeSlotUserTimeZone.weekday;
            }
            return a.timeSlotUserTimeZone.startTimeInUserTimeZone.localeCompare(
                b.timeSlotUserTimeZone.startTimeInUserTimeZone,
            );
        });

    const toggleSelection = (timeSlot: PeerGroupTimeSlot) => {
        const id = getPeerGroupTimeSlotId(timeSlot);
        if (selectedTimeSlotIds.includes(id)) {
            setSelectedTimeSlotIds(selectedTimeSlotIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedTimeSlotIds([...selectedTimeSlotIds, id]);
        }
    };

    const handlePeerGroupSelectionConfirm = async () => {
        if (!hasSelectedEnoughTimeSlots) {
            showSnackbar("Please select at least two time slots", "error");
            setConfirmationPopupOpen(false);
            return;
        }

        const selectedTimeSlots = timeSlotsWithUTCAndUserTimeZone
            .map((timeslot) => timeslot.timeSlotUTC)
            .filter((timeSlot) => selectedTimeSlotIds.includes(getPeerGroupTimeSlotId(timeSlot)));

        const result = await saveSelectedPeerGroupTimeSlots({
            variables: {
                courseId: courseId,
                selectedPeerGroupTimeSlots: selectedTimeSlots,
            },
        });

        if (result.data?.saveSelectedPeerGroupTimeSlotsForUser) {
            navigationHistory.push(routes.coursePeerGroup(courseId));
        } else {
            showSnackbar("Something went wrong. Please try again.", "error");
        }
    };

    return (
        <PageWrapper removeMarginTop>
            {hasUserSelectedTimezone ? (
                <BackArrowButton onClick={() => setHasUserSelectedTimezone(false)} />
            ) : (
                <BackArrowButton route={routes.coursesDashboard()} />
            )}

            <Typography variant="h2" sx={{ mb: 0 }}>
                Select your peer group time slots
            </Typography>

            {userHasAlreadySelectedPeerGroupTimeSlots && (
                <Alert severity="warning">
                    You have already selected your peer group time slots. If you select new time
                    slots, your previous selection will be overwritten.
                </Alert>
            )}

            {!hasUserSelectedTimezone ? (
                <>
                    <Typography variant="body1">
                        First, select your timezone so that we can show you the available time slots
                        in your local time.
                    </Typography>
                    <CSSelectField
                        options={IANATimezones}
                        value={timezone}
                        onChange={(value) => {
                            setTimezone(value);
                        }}
                        label="Time zone"
                        required
                    />
                    <Button
                        variant="primary"
                        onClick={() => {
                            setHasUserSelectedTimezone(true);
                        }}
                        fullWidth={isMobile}
                    >
                        Continue
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="body1" sx={{ mb: 0 }}>
                        Select at least two time slots.
                        <strong> Only select times that you can commit to.</strong>
                    </Typography>

                    <Chip label={`Your timezone: ${timezone}`} variant="green" />

                    <Box
                        sx={{
                            display: "grid",
                            gap: 1,
                            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                            mt: 2,
                        }}
                    >
                        {timeSlotsWithUTCAndUserTimeZone.map((timeSlot) => {
                            const timeSlotId = getPeerGroupTimeSlotId(timeSlot.timeSlotUTC);
                            const isSelected = selectedTimeSlotIds.includes(timeSlotId);

                            return (
                                <Box
                                    key={timeSlotId}
                                    onClick={() => toggleSelection(timeSlot.timeSlotUTC)}
                                    sx={{
                                        border: `2px solid ${isSelected ? theme.palette.accentEarthy : theme.palette.grey400}`,
                                        cursor: "pointer",
                                        p: 1,
                                        borderRadius: `${theme.borderRadius.small}px`,
                                        backgroundColor: isSelected
                                            ? theme.palette.neutralWarm
                                            : "transparent",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            mb: 0,
                                            fontSize: theme.typography.pxToRem(15),
                                            textAlign: "center",
                                        }}
                                    >
                                        {formatPeerGroupTimeSlot(
                                            timeSlot.timeSlotUserTimeZone,
                                            timezone,
                                            true,
                                        )}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>

                    <Tooltip
                        title="Select at least two time slots"
                        disableHoverListener={hasSelectedEnoughTimeSlots}
                        disableFocusListener={hasSelectedEnoughTimeSlots}
                        disableTouchListener={hasSelectedEnoughTimeSlots}
                    >
                        <span>
                            <Button
                                variant="primary"
                                onClick={() => setConfirmationPopupOpen(true)}
                                sx={{ mt: 2 }}
                                disabled={!hasSelectedEnoughTimeSlots}
                                fullWidth={isMobile}
                            >
                                Continue
                            </Button>
                        </span>
                    </Tooltip>
                </>
            )}

            <CSDialog open={confirmationPopupOpen} onClose={() => setConfirmationPopupOpen(false)}>
                <Typography variant="h2">Confirm your selection</Typography>
                <Typography variant="body1">You have selected the following time slots:</Typography>

                <ul style={{ paddingLeft: theme.spacing(2) }}>
                    {timeSlotsWithUTCAndUserTimeZone
                        .filter((timeSlot) =>
                            selectedTimeSlotIds.includes(
                                getPeerGroupTimeSlotId(timeSlot.timeSlotUTC),
                            ),
                        )
                        .map((timeSlot) => {
                            return (
                                <li key={getPeerGroupTimeSlotId(timeSlot.timeSlotUTC)}>
                                    {formatPeerGroupTimeSlot(
                                        timeSlot.timeSlotUserTimeZone,
                                        timezone,
                                        true,
                                    )}
                                </li>
                            );
                        })}
                </ul>

                <Typography variant="body1">
                    You won't be able to change your selection later. You could be assigned to any
                    of these time slots. Are you sure you can commit to these times?
                </Typography>
                <Button
                    variant="primary"
                    onClick={handlePeerGroupSelectionConfirm}
                    fullWidth={isMobile}
                >
                    Confirm
                </Button>
            </CSDialog>
        </PageWrapper>
    );
};
